<template>
  <!-- begin:: Header -->
  <div id="kt_header" ref="kt_header" class="header" v-bind:class="headerClasses">
    <div class="container-fluid d-flex justify-content-between">
      <!-- LOGO -->
      <div class="p-2">
        <img src="/media/logos/CIB_Logo.jpeg" alt="Collective Insurance Brokers" height="60px" />
      </div>

      <!-- WIZARD TITLE AND SUBTITLE -->
      <div class="text-center">
        <span class="text--black font-size-h2 font-weight-bold">
          {{ wizardTitle }}
        </span>
        <br />
        <span class="text-primary font-size-h4 font-weight-bold">
          {{ wizardSubtitle }}
        </span>
      </div>

      <div class="pt-0" style="max-width: 450px;">
        <table class="table table-sm font-size-base font-weight-bold no-row-border no-vertical-padding"
          v-if="transactionStatus">
          <tbody>
            <tr>
              <td style="width: 150px; margin-left: 0px;">Status:</td>
              <td><b-badge variant="primary" class="font-size-md px-2 py-1">{{ transactionStatus.status.toUpperCase() }}</b-badge></td>
            </tr>
            <tr v-if="transactionStatus.hasOwnProperty('statusDetails')">
              <td style="width: 150px;">Details:</td>
              <td class="text-primary word-wrap">{{ transactionStatus.statusDetails }}</td>
            </tr>
            <tr v-if="transactionStatus.nextStep">
              <td style="width: 150px;">Next Step:</td>
              <td class="text-primary">{{ transactionStatus.nextStep }}</td>
            </tr>
            <tr v-if="transactionStatus.hasOwnProperty('dueInWeek')">
              <td style="width: 150px;">Due in Week:</td>
              <td class="text-primary">{{ transactionStatus.dueInWeek }}
                <span v-if="transactionStatus.weekStartDate">
                  (Week starting {{
                    transactionStatus.weekStartDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY")
                  }})
                </span>
              </td>
            </tr>
            <tr v-if="transactionSubStatus">
              <td style="width: 150px;">Sub-Status:</td>
              <td><b-badge variant="secondary" class="px-2 py-1">{{ transactionSubStatus }}</b-badge></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
  <!-- end:: Header -->
</template>

<style>
.no-row-border tr {
  border: none;
}

.no-row-border td {
  border: none;
}

.no-vertical-padding td {
  padding-top: 0;
  padding-bottom: 0;
}

.no-vertical-padding tr:first-child td {
  padding-top: 0.25rem;
}

.no-vertical-padding tr:last-child td {
  padding-bottom: 0.25rem;
}

td:first-child {
  width: 90px;
}

.font-size-md {
  font-size: 103% !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

export default {
  name: "KTHeader",
  components: {},
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    wizardTitle: function () {
      let state = this.$store.state;
      // Set page title
      if (state.wizardTitle) {
        document.title = state.wizardTitle;
      }

      return state.wizardTitle;
    },

    wizardSubtitle: function () {
      return this.$store.state.wizardSubtitle;
    },

    transactionStatus: function () {
      return this.$store.state.transactionStatus;
    },

    transactionSubStatus: function () {
      return this.$store.state.transactionSubStatus;
    },
  },

  methods: {},
};
</script>
